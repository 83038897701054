import { graphql } from "gatsby";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { ProductContactModal, ServerError } from "@modules/shared/components";
import { ModalName } from "@modules/shared/enums";
import { modalState } from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";
import { SimulatorLayout } from "@modules/simulator";

const InternalServerErrorPage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const { t } = useTranslation();
    const [shownModal, setModal] = useRecoilState(modalState);

    const handleContact = () => {
        setModal(ModalName.ProductContact);
    };

    return (
        <>
            <SimulatorLayout
                title={t("serverErrorPage.title")}
                page={page}
                language={language}
                allPages={allPages}
            >
                <ServerError onContactClick={handleContact} />
            </SimulatorLayout>
            {shownModal === ModalName.ProductContact && (
                <ProductContactModal onClose={() => setModal(undefined)} />
            )}
        </>
    );
};

export default InternalServerErrorPage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
